'use strict'

import fetch from '../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import { isParsleyForm, isValid } from "../../libs/@elements/parsley-bootstrap-validation";
import { submitAjaxForm } from '../../scripts/reCaptchaV3';

export function initInScope($scope) {

    let $formButton = $scope.find('.js-add-notify-availability');
    let $form = $scope.find('.js-add-notify-availability__form');
    let pendingRequest;
    let $content = $scope.find('.modal-body');
    const $loading = $('.js-modal__loading');

    $formButton.on('click', (e) => {

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        e.preventDefault();

        if (isParsleyForm($form) && !isValid($form)) {
            return;
        }

        showLoading();

        let url = $(e.currentTarget).data('url');
        let formData = new URLSearchParams(formDataEntries($form[0]));

        submitAjaxForm(url, formData, sendRequest);
    });

    function sendRequest(url, formData) {

        pendingRequest = fetch(url, {
            method: 'POST',
            body: formData
        });

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if (res.success) {
                if (res.html) {
                    const $response = res.html;

                    $content.html($response);
                    $formButton.attr('disabled', 'disabled');
                    $formButton.addClass('d-none');
                }
            }
        }).finally(() => {
            hideLoading();
        })
    }

    function showLoading() {
        $loading.removeAttr('hidden');
    }

    function hideLoading() {
        $loading.attr('hidden', true);
    }
}