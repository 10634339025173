"use strict";

import Splide from '@splidejs/splide';
import Tab from 'bootstrap-5/js/dist/tab';

export function init() {

    document.querySelectorAll('.js-tab-module-splide').forEach(container => initOne(container))

    const hash = window.location.hash || null;
    if (hash === null) {
        return;
    }
    const targetTab = document.querySelector(`[href="${hash}"]`);

    if (targetTab) {
        Tab.getOrCreateInstance(targetTab).show()
    }
}

export function initOne(sliderElement) {
    const splideOptions = {
        type: 'slide',
        keyboard: true,
        focus: 'center',
        arrows: false,
        drag: true,
        pagination: false,
        isNavigation: true,
        fixedWidth: '130px',
        gap: '10px',
        padding: '20px'
    }

    const slider = new Splide(sliderElement, splideOptions).mount();

    //use the DESKTOP bootstrap tab to switch the tab contents
    function activateTab(navLink) {
        const targetElement = document.getElementById(navLink.dataset.bsTabTarget)
        Tab.getOrCreateInstance(targetElement).show()
    }

    function activateNavLink(navLink) {
        navLink.setAttribute('aria-selected', 'true')
        navLink.classList.add('active')
    }

    function deactivateNavLink(navLink) {
        navLink.setAttribute('aria-selected', 'false')
        navLink.classList.remove('active')
    }

    slider.on('move', (newIndex, prevIndex)=>{
        const newSlide = slider.Components.Slides.getAt(newIndex).slide
        const newNavLink = newSlide.querySelector('.tab-module_nav-link')

        const prevSlide = slider.Components.Slides.getAt(prevIndex).slide
        const prevNavLink = prevSlide.querySelector('.tab-module_nav-link')

        deactivateNavLink(prevNavLink)
        activateNavLink(newNavLink)

        activateTab(newNavLink)
    })
}
