'use strict'

import fetch from '../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import { isParsleyForm, isValid } from "../../libs/@elements/parsley-bootstrap-validation";
import { submitAjaxForm } from '../../scripts/reCaptchaV3';
import throwError from "../../libs/@elements/throw-error"

const Selectors = {
     AjaxReviewFormModal: '#feedbackModal',
     AjaxReviewFormModalContentWrapper: '.ajax-review-form-modal-content-wrapper'
}

const Attributes = {
    AjaxReviewFormModalAction: 'data-ajax-review-form-modal-action'
}

const States = {
    IsLoading: 'is-loading'
}

const AJAX_REVIEW_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT = 'ajaxReviewFormModalRequest:renderedContent';
const AJAX_REVIEW_FORM_MODAL_REQUEST_FINISHED_EVENT = 'ajaxReviewFormModalRequest:finished';
const AJAX_REVIEW_FORM_MODAL_REQUEST_ERRORED_EVENT = 'ajaxReviewFormModalRequest:errored';

let $ajaxReviewFormModal;
let $ajaxReviewFormModalContentWrapper;
let ajaxReviewFormModalContentWrapperHTML = null;
let ajaxReviewFormModalAction = false;
let ajaxReviewFormModalRequest = false;

export function initInScope() {
    const $scope = $(document);
    $ajaxReviewFormModal = $scope.find(Selectors.AjaxReviewFormModal).length ? $scope.find(Selectors.AjaxReviewFormModal) : throwError("1657550427 Product rating JS – Modal missing");
    $ajaxReviewFormModalContentWrapper = $ajaxReviewFormModal.find(Selectors.AjaxReviewFormModalContentWrapper).length ? $ajaxReviewFormModal.find(Selectors.AjaxReviewFormModalContentWrapper) : throwError("1657550945 Product rating JS – Modal content wrapper missing");
    ajaxReviewFormModalAction = $ajaxReviewFormModal.attr(Attributes.AjaxReviewFormModalAction) ? $ajaxReviewFormModal.attr(Attributes.AjaxReviewFormModalAction) : throwError("1657550452 Product rating JS – Modal ajax form action missing");

    $ajaxReviewFormModal.on('show.bs.modal', onModalShow);
    $ajaxReviewFormModal.on(AJAX_REVIEW_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT, onAjaxReviewFormModalRequestContentRendered);
    $ajaxReviewFormModal.on(AJAX_REVIEW_FORM_MODAL_REQUEST_FINISHED_EVENT, onAjaxReviewFormModalRequestFinished);
    $ajaxReviewFormModal.on(AJAX_REVIEW_FORM_MODAL_REQUEST_ERRORED_EVENT, (event, eventData) => onAjaxReviewFormModalRequestErrored(event, eventData));
}

function onModalShow() {
    setAjaxReviewFormModalContent();
}

function setAjaxReviewFormModalContent() {
    // If request is already pending or content is already loaded, do nothing
    if (ajaxReviewFormModalRequest || ajaxReviewFormModalContentWrapperHTML ) return;

    ajaxReviewFormModalRequest = false;
    $ajaxReviewFormModalContentWrapper.html('')

    $ajaxReviewFormModal.addClass(States.IsLoading);

    ajaxReviewFormModalRequest = fetch(ajaxReviewFormModalAction, { method: 'GET' });

    ajaxReviewFormModalRequest.then(response => {
        ajaxReviewFormModalContentWrapperHTML = response.clone().json();
        return ajaxReviewFormModalContentWrapperHTML
    }).then(response => {
        if (!response || !response.success) throw new Error('1657550734 Product rating JS - Invalid ajax review form response')
        if (!response.html) throw new Error('1657550973 Product rating JS - Empty ajax review form response')

        $ajaxReviewFormModalContentWrapper.html(response.html);
        $ajaxReviewFormModal.trigger(AJAX_REVIEW_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT)
    }).finally(() => {
        $ajaxReviewFormModal.trigger(AJAX_REVIEW_FORM_MODAL_REQUEST_FINISHED_EVENT)
    }).catch(error => {
        $ajaxReviewFormModal.trigger(AJAX_REVIEW_FORM_MODAL_REQUEST_ERRORED_EVENT, [error])
    })
}

function onAjaxReviewFormModalRequestContentRendered(event) {
    initProductRating();
}

function onAjaxReviewFormModalRequestFinished(event) {
    $ajaxReviewFormModal.removeClass(States.IsLoading);
    ajaxReviewFormModalRequest = false
}

function onAjaxReviewFormModalRequestErrored(event, eventData) {
    console && console.error && console.error(eventData);
}

function initProductRating() {
    const $scope = $ajaxReviewFormModal;

    // Re-initialize floating labels
    window.app && window.app.modules && window.app.modules.floatingLabel && window.app.modules.floatingLabel($scope);

    // Re-initialize reCaptcha
    window.app && window.app.modules && window.app.modules.reCaptchaV3 && window.app.modules.reCaptchaV3($scope);

    let $rating = $scope.find('.js-rate-product');
    initRating();

    let $addRating = $scope.find('.js-add-product-rating');
    let $addRatingForm = $scope.find('.js-add-product-rating__form');
    const $ratingInputs = $rating.find('input');
    let currentContent;
    let pendingRequest;
    let $content = $scope.find('.js-content');

    const $loading = $('.js-modal__loading');

    $addRating.on('click', (e) => {

        if(pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        e.preventDefault();

        if (isParsleyForm($addRatingForm) && !isValid($addRatingForm)) {
            return;
        }

        showLoading();

        let url = $(e.currentTarget).data('url');
        let formData = new URLSearchParams(formDataEntries($addRatingForm[0]));

        submitAjaxForm(url, formData, sendRequest);
    });

    function sendRequest(url, formData) {

        pendingRequest = fetch(url, {
            method: 'POST',
            body: formData
        });

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if(res.success){
                if(res.html){
                    const $response = res.html;

                    currentContent = $content.html();

                    $content.html($response);
                    $addRating.attr('disabled', 'disabled');
                    $addRating.addClass('d-none');
                }
            }
        }).finally(() => {
            hideLoading();
        })
    }

    function showLoading(){
        $loading.removeAttr('hidden');
    }
    function hideLoading(){
        $loading.attr('hidden', true);
    }

    function initRating(){
        $rating.off('mouseover');
        $rating.off('click');
        $rating = $scope.find('.js-rate-product');
        $rating.on('mouseleave', (e) => {
            changeRatingValue(e);
        })
        $rating.on('mouseover', (e) => {
            let isChecked = $scope.find('.js-rate-product__star').find('input').is(':checked');
            if(!isChecked){
                changeRatingValue(e);
            }
        });
        $rating.on('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            changeRatingValue(e);
            $ratingInputs.attr('checked', false);
            $(e.target).closest('.js-rate-product__star').find('input').attr('checked', true);
            $rating.off('mouseleave');
        });
    }
    function changeRatingValue(e) {
        const numberOfStars = $(e.target).closest('.js-rate-product__star').data('stars');
        if(numberOfStars){
            if(numberOfStars){
                for(let i = 0; i < $rating.children().length; i++){
                    if(i < numberOfStars){
                        $($rating.children()[i]).find('.js-rate-product-star-visual').addClass('icon-ortovox-star').removeClass('icon-star-empty')
                    }else{
                        $($rating.children()[i]).find('.js-rate-product-star-visual').removeClass('icon-ortovox-star').addClass('icon-star-empty')
                    }
                }
            }
        }
    }
}
